import React from 'react'
import { graphql } from 'gatsby'
import SSRProvider from 'react-bootstrap/SSRProvider'

import BlogItems from '../../components/blogItems'
import DefaultHeader from '../../components/default-header'
import Footer from '../../components/footer'
import GdprPanel from '../../components/gdpr'
import Navigation from '../../components/navigation'
import Seo from '../../components/seo'

import '../../styles/content-library.scss'
import NewsletterSignup from '../../components/newsletterSignUp'

const Page = ({ data }) => {

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader headline="Content Resources" heroBackground="url('https://web-cdn.meridianuniversity.edu/header-images/content-library-hero-image-2023.webp')" heroMobileBackground="url('https://web-cdn.meridianuniversity.edu/header-images/content-library-hero-image-mobile-2023.webp')" subHeadline="Articles and Resources Exploring the Future of Business, Education, Psychology, and Leadership" />
            <div id="body" className="content-library-overview">
                <BlogItems items={data} />
            </div>
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = () => (
  <Seo 
      title="Content Resources" 
      description="A Collection of Content used to help potential Meridian University Students" 
      keywords="Meridian University, Content Resources" 
      pathname="content/"
      ogImage="social-cards/content-library-hero-image-2023-og-image.webp"
      cardImage="social-cards/content-library-hero-image-2023-card-image.webp"
  />
)

export default Page

export const pageQuery = graphql`
{    
    allMdx(
        filter: {frontmatter: {tags: {eq: "content"}}}
        sort: {fields: [frontmatter___date], order: DESC}
      ) {
        edges {
          node {
            id
            slug
            excerpt
            frontmatter {
              category
              dateCreated
              date
              headline
              contentThumb
            }
          }
        }
    }
}
`